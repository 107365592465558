<template>
    <!-- <div>
        <div v-for="(item,index) in 5" :key="index">
            <div v-if="item==1">
                这里是第{{item}}层div
                <shrink-view>
                <div style="background-color:aquamarine;">
                    <div v-for="ce in 15" :key="ce">123123</div>
                </div>
                </shrink-view>
            </div>
            <div v-else>这里是第{{item}}层div123</div>
        </div>
    </div> -->
    <div class="newslist">
        <ul>
        <li v-for="(item,index) in linkitem" :key="index">
            <p class="p" ref="liCon">{{item.content}}</p>
            <div class="open" @click="open(item,index)">
            <div v-if="!item.openFlag">【展开】</div>
            <div v-else>【收缩】</div>
            </div>
        </li>
        </ul>
    </div>
</template>

<style>
.newslist ul li p {
    font-size: 14px;
    color: #555;
    line-height: 25px;
    height: 50px;
    overflow: hidden;
    transition: height .3s;
}
</style>

<script>
import { newGuid} from '/src/common' 
import { httpAES } from '/src/services'

    export default {
        name:"ceshi",
        data () {
            return {
                PayKinds:''
            }
        },
         mounted(){
             this.$nextTick(()=> {
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                //获取支付方式
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo.User_ID,
                    DataTypeNames:["PayType"]
                }
                this.httpPost('Bestech.CloudPos.GetBaseData',param,'数据加载中...').then((data)=>{
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.PayKinds = data.ResponseBody.PayKinds
                        // this.PayKinds.map(Kind=>{
                        //     Kind.PayTypes.map(pay=>{
                        //         /**现金 */
                        //         // if(pay.PAY_ID=='67a33e74-e428-43f9-b152-ad6e1c0bfcaf'){
                                    
                        //         // }
                        //     })
                        // })
                        console.log('PayKinds:'+JSON.stringify(this.PayKinds));
                        //this.payType = data.ResponseBody.PayKinds
                    }
                })
             })
         },
        methods: {
            /**接口调用方法 */
            httpPost(http,param,title){
                    const loading = this.$loading({
                        text: title,
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                let d = httpAES.post(http, param).then((d)=>{
                        loading.close()
                        return d;
                    }).catch((e)=>{
                        loading.close()
                        this.$message.error(e.ResponseHeader.ResultDesc);
                        //this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                return d;
            },
        },
    }
</script>